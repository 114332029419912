import React from 'react'
import './style.css'
const ComingSoon = () => {
  return (
    <>
    <div className="np-section__inner col-100 floatLft">
      <div className="np-sectionImg floatLft">
        <img src={process.env.PUBLIC_URL + '/assets/images/img.png'} alt="" />
      </div>
      <div className="np-sectionTxt col-100 floatLft textCenter">
        <h3>We are creating something amazing</h3>
        <p>we will launch our website soon.</p>
      </div>
    </div>

    </>
  )
}

export default ComingSoon