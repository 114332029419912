import React from 'react'
import Header from '../components/Header'
import ComingSoon from '../components/ComingSoon'

const ComingSoonPage = () => {
  return (
    <div>
      <header class="np-header col-100 floatLft">
        <Header />
      </header>
      <section class="np-section col-100 floatLft">
        <ComingSoon />
      </section>
    </div>
  )
}

export default ComingSoonPage