import { Route, Routes } from "react-router-dom";
import ComingSoonPage from "./pages/ComingSoonPage";

function App() {
  return (
    <div className="App">
      <Routes>
            <Route path="/" element={<ComingSoonPage />} />
          </Routes>
    </div>
  );
}

export default App;
