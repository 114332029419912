import React from 'react'
import './style.css'
const Header = () => {
  return (
    <>
      <div className="home-wrapper">
        <div class="np-headerImg col-100 floatLft">
          <img src={process.env.PUBLIC_URL + '/assets/images/headerLogo.svg'} alt="" />
        </div>
      </div>
    </>
  )
}

export default Header